<template>
  <img :src="logo">
</template>
<script>
import logo from '@/assets/images/logo/logo.png';

export default {
  data() {
    return {
      logo,
    };
  },
};
</script>

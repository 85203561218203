<template>
  <div class="the-navbar__user-meta flex items-center" v-if="activeUserInfo">
    <div class="text-right leading-tight hidden sm:block">
      <p >
        <span class="font-semibold">Bonjour </span> {{ username }}
        <span class="divider" />
        <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
        <span class="ml-2" style="cursor: pointer;" @click="logout()">Déconnexion</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    username() {
      const { user } = this.$store.state.AppActiveUser;
      return `${user.firstName} ${user.lastName}`;
    },
  },
  methods: {
    logout() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('this.user.authenticated');
      localStorage.removeItem('this.user.refresh_token');
      localStorage.removeItem('userInfo');
      this.$store.commit('UPDATE_USER_INFO', {
        user: {},
        displayName: null,
        commandes: {},
      });
      this.$store.state.calendar = {};
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
<style>
  .divider {
    background: #dd2253;
    display: inline-block;
    height: 16px;
    margin: 0 10px;
    width: 1px;
  }
</style>

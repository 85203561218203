<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown v-if="notificationShow" vs-custom-content vs-trigger-click class="cursor-pointer">
    <feather-icon icon="BellIcon" class="cursor-pointer mt-1 sm:mr-6 mr-2" :badge="unreadNotifications.length" />

    <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">

      <div class="notification-top text-center p-5 bg-primary text-white">
        <h3 class="text-white">{{ unreadNotifications.length }} </h3>
        <p v-if="type == 'rendezVous'" class="opacity-75">Rendez-vous à statuer</p>
        <p v-else class="opacity-75">Nouvelles commandes</p>
      </div>

      <component :is="scrollbarTag" ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0 mb-10" :settings="settings" :key="$vs.rtl">
        <ul class="bordered-items">
          <li v-for="ntf in unreadNotifications"  :key="ntf.id" class="flex justify-between px-4 py-4 notification cursor-pointer">
            <a class="font-medium block notification-title" @click="read(`${ntf.id}`,`${ntf.commande}`)">

            <div class="flex items-start">
              <feather-icon icon="XCircleIcon" :svgClasses="['primary', 'stroke-current mr-1 h-6 w-6']"></feather-icon>
              <div class="mx-2 black">
                <span class="font-medium block notification-title" >Commande : {{ ntf.commande }}</span>
                <small>{{ ntf.date }}</small>
              </div>
            </div>

            </a>
          </li>
        </ul>
      </component>

      <div class="
        checkout-footer
        fixed
        bottom-0
        rounded-b-lg
        text-primary
        w-full
        p-2
        font-semibold
        text-center
        border
        border-b-0
        border-l-0
        border-r-0
        border-solid
        d-theme-border-grey-light
        cursor-pointer">
      </div>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import axios from '@/axios';
// import store from "@/store/store.js";
import router from '@/router';
// let user = store.state.AppActiveUser.user

export default {
  components: {
    VuePerfectScrollbar,
  },
  data() {
    return {
      type: '',
      notificationShow: false,
      unreadNotifications: '',
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.60,
      },
    };
  },
  computed: {
    scrollbarTag() { return this.$store.getters.scrollbarTag; },
  },
  methods: {
    getNotif() {
      let url = '';
      const dataResult = [];
      if ((this.$store.state.AppActiveUser.user.roles).includes('ROLE_SUPER_ADMIN')) {
        url = 'api/notifications?etat=EA';
      } else if ((this.$store.state.AppActiveUser.user.roles).includes('ROLE_ADMIN')) {
        url = `api/notifications?employee=${this.$store.state.AppActiveUser.user.id}`;
      } else {
        url = `api/rendez_vouses?negociateur=${this.$store.state.AppActiveUser.user.id}&statut=A statuer`;
        this.type = 'rendezVous';
      }
      this.notificationShow = true;
      axios.get(url, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        let oneLine = {};
        if (this.type == 'rendezVous') {
          response.data.forEach((value) => {
            oneLine = {
              id: value.id,
              commande: `${value.commande.reference} | RDV : ${value.id}`,
              date: value.dateDebut,
            };
            dataResult.push(oneLine);
          });
          this.unreadNotifications = dataResult;
        } else {
          this.unreadNotifications = response.data;
        }
      });
    },
    read($id, $idCommande) {
      if (this.type == 'rendezVous') {
        this.getNotif();
        router.push(`/rendez-vous/${$id}`);
      } else {
        axios.delete(`api/notifications/${$id}`, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }).then((response) => {
          this.getNotif();
          router.push({ name: 'admin-commandes-id', params: { id: $idCommande } });
          return response.data;
        });
      }
    },
  },
  mounted() {
    this.getNotif();
  },
};

</script>
